// useTheme.js
import { computed } from 'vue';
import {useAuth0} from "@auth0/auth0-vue";

export function checkPermissionByEmail() {
    const auth = useAuth0();
    const isUserExclusive = computed(() => {
        if(auth.user.value.email){
            const emailParts = auth.user.value.email.split('@');
            const domain = emailParts[1];
            switch (domain) {
                case 'moore-drv.nl':
                case 'crossminds.nl':
                case 'zantboer.nl':
                case 'redefine-it.eu':
                case 'drv.nl':
                case 'ruitenburg.nl':
                case 'imaginecreativeagency.nl':
                    return true;
                default:
                    return false;
            }
        }
        else{
            return false;
        }
    });

    return {
        isUserExclusive
    };
}

export function checkAdminByEmail(){
    const auth = useAuth0();
    const isAdmin = computed(() => {
        if(auth.user.value.email) {
            switch (auth.user.value.email) {
                case 's.koedoot@moore-drv.nl':
                case 'd.marcelissen@imaginecreativeagency.nl':
                    return true;
                default:
                    return false;
            }
        }
    });

    return {
        isAdmin
    };
}
