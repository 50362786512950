<template>
  <title>Overzicht | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="col-md-4 col-12 container-content" :class="{'cptl cpxxl cpbm min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen, 'font-white': isDarkPage}">
        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeTextXl">Nieuwe kortingscode</h2>
          </div>
          <div class="du-controls-content d-flex">
            <div class="w-100">
              <div class="du-question-control-left d-md-inline-block d-block cmbs" :class="{'w-100': windowCheckerData.isTabletScreen}">
                <div class="cmbm">
                  <p>Vul het formulier hieronder voor een nieuwe kortingscode.</p>
                </div>
              </div>
              <form @submit.prevent="">
                <div class="row">
                  <div class="col-12">
                    <p class="du-r-input-annotation">E-mail adres</p>
                    <span class="error mt-3 d-block error-mail" v-if="errors.email && !isEmailValid">{{ errors.email }}</span>
                    <div class="du-question-input mt-4">
                      <input type="email" class="du-input du-r-input" id="email" v-model="form.email" @blur="validateField('email')">
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="du-r-input-annotation">Code (min. 9 karakters</p>
                    <span class="error mt-3 d-block" v-if="errors.code && !isCodeValid">{{ errors.code }}</span>
                    <div class="du-question-input mt-4 row d-flex justify-content-evenly">
                      <input type="text" min="9" class="du-input-style col-5" id="code" v-model="form.code" @blur="validateField('code')" @input="form.code = form.code.toUpperCase()">
                      <button v-on:click="generateCode" class="du-button col-6">GENEREER CODE</button>
                    </div>
                  </div>
                  <div class="col-6">
                    <p class="du-r-input-annotation">Geldig vanaf</p>
                    <span class="error mt-3 d-block" v-if="errors.startDate && !isStartDateValid">{{ errors.startDate }}</span>
                    <div class="du-question-input mt-4 w-100">
                      <input type="date" class="du-input-style w-100" id="start-date" v-model="form.startDate" @blur="validateField('start-date')">
                    </div>
                  </div>
                  <div class="col-6">
                    <p class="du-r-input-annotation">Geldig tot</p>
                    <span class="error mt-3 d-block" v-if="errors.endDate && !isEndDateValid">{{ errors.endDate }}</span>
                    <div class="du-question-input mt-4 w-100">
                      <input type="date" class="du-input-style w-100" id="end-date" v-model="form.endDate" @blur="validateField('end-date')">
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="du-r-input-annotation">Kortingsbedrag</p>
                    <span class="error mt-3 d-block" v-if="errors.amount && !isAmountValid">{{ errors.amount }}</span>
                    <div class="du-question-input mt-4 w-100">
                      <span class="euro-sign">€</span>
                      <input type="text" class="du-input-style du-input-amount" id="amount" v-model="form.amount" @blur="validateField('amount')" @input="validateAmountInput">
                    </div>
                  </div>
                </div>
                <!-- Add more form fields as needed with onBlur validation -->
                <button v-on:click="submitForm" class="du-button mt-4">CODE AANMAKEN
                  <i class="fa-solid fa-chevron-right thick-icon"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="d-md-block d-none col-md-8 col-12 du-column-bg-white" :class="{'cptl cpxxl min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 d-flex justify-content-start">
            <div class="du-content-title du-data-title mt-0 mb-4 d-flex align-items-center">
              <h2>KORTINGSCODES</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-flex align-items-center col-5" >
            <div class="du-data-overview-datatitle">
              <h3>E-MAILADRES</h3>
            </div>
          </div>

          <div class="d-flex align-items-center col-2">
            <div class="du-data-overview-datatitle">
              <h3>CODE</h3>
            </div>
          </div>

          <div class="align-items-center col-2">
            <div class="du-data-overview-datatitle">
              <h3>GELDIG TOT</h3>
            </div>
          </div>

          <div class="col-2 d-flex align-items-center col-2">
            <div class="du-data-overview-datatitle">
              <h3>STATUS</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="du-data-overview-seperator-thick"></div>
          </div>
          <div v-if="codes.length > 0" v-for="code in codes" class="col-12" >
            <div class="du-data-overview-data">
              <div class="row">
                <div class="d-flex align-items-center col-5">
                  <div class="du-data-overview-text">
                    <h4>{{code.cod_emailaddress}}</h4>
                  </div>
                </div>
                <div class="d-flex align-items-center col-2">
                  <div class="du-data-overview-text">
                    <p>{{ code.cod_code }}</p>
                  </div>
                </div>
                <div class="align-items-center col-2">
                  <div class="du-data-overview-text">
                    <p>{{ code.cod_custom_end_datetimestamp }}</p>
                  </div>
                </div>
                <div class="d-flex align-items-center col-2" :class="{'col-2' : !code.test_current_user_id && !code.test_last_visited_datetimestamp, 'col-3': code.test_current_user_id && code.test_last_visited_datetimestamp}">
                  <div class="du-data-overview-text" :class="{'d-flex align-items-center': code.test_current_user_id && code.test_last_visited_datetimestamp}">
                    <p v-if="code.cod_status == 1">beschikbaar</p>
                    <p v-if="code.cod_status == 0">gebruikt</p>
                  </div>
                </div>
                <div class="d-flex align-items-center col-1">
                  <div v-if="!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen && !code.test_current_user_id && !code.test_last_visited_datetimestamp" class="du-data-overview-text du-data-overview-text-last du-data-overview-link d-flex justify-content-center">
                    <div v-on:click="deleteCode(code.cod_id)">
                      <i class="fa-solid fa-trash"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="du-data-overview-seperator"></div>
          </div>
          <div v-if="codes.length == 0" class="col-12">
            <div class="du-data-overview-data">
              <h3 v-if="codes.length == 0">ER ZIJN NOG GEEN KORTINGSCODES GEMAAKT.</h3>
              <h3 v-if="isLoading">LADEN VAN KORTINGSCODES...</h3>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
import {useAuth0} from "@auth0/auth0-vue";
import {ref, computed, reactive} from "vue";
import {useTheme} from "@/components/utils/theme";

export default {

  name: "RequestOverview",
  components: {WindowChecker},
  setup() {
    const { isDarkPage } = useTheme()
    const auth0 = useAuth0();
    const codes = ref([]);
    // Definieer de getRequests functie
    const getRequests = async () => {
      codes.value = []
      const obj = {
        selectAll: "true",
        from: "drv_discount_codes",
        orderBy: "cod_id DESC",
        testQuery: "false",
        join: {
          st1: ''
        }
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      };
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/dynamicGet/index.php", requestOptions);
      const json = await response.json();

      if (json.success === true && json.data !== false) {
        codes.value = json.data;
      }
      else{
        codes.value = [];
      }
    };

    const form = reactive({
      email: '',
      code: '',
      startDate: '',
      endDate: '',
      amount: ''
    });

    const errors = ref({});

    const isEmailValid = computed(() => form.email.includes('@'));
    const isCodeValid = computed(async () => form.code.trim() !== '' && await codeChecker());
    const isStartDateValid = computed(() => form.startDate !== '');
    const isEndDateValid = computed(() => form.endDate !== '');
    const isAmountValid = computed(() => {
          const regex = /^\d+(\.\d{2})?$/;
          return regex.test(form.amount);
        }
    );
// Add more computed properties for other form fields as needed
// Alter computed properties to validate various aspects of the input

    const validateField = async (field) => {
      if (field === 'email' && !isEmailValid.value) {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.email = 'E-mail is verplicht.';
      }
      if (field === 'code') {
        const checker = await codeChecker()
        if (checker === false) {
          errors.value[field] = ''; // Clear previous error for the field
          errors.value.code = 'Code is al in gebruik. Probeer een andere code.';
        } else if (!isCodeValid.value) {
          errors.value[field] = ''; // Clear previous error for the field
          errors.value.code = 'Code is verplicht.';
        }

      }
      if (field === 'start-date' && !isStartDateValid.value) {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.startDate = "Geldig vanaf is verplicht.";
      }
      if (field === 'end-date' && !isEndDateValid.value) {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.endDate = 'Geldig tot is verplicht.';
      }
      if (field === 'amount' && !isAmountValid.value) {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.amount = 'Kortingsbedrag moet met 2 decimalen';
      }
      // Add more validation checks for other form fields as needed
    };

    const submitForm = async () => {
      errors.value = {}; // Clear previous errors
      validateField('email');
      validateField('code');
      validateField('start-date');
      validateField('end-date');
      validateField('amount');
      // Add more validation checks for other form fields as needed
      if (Object.keys(errors.value).length === 0) { //if no errors are found
        // Validation passed, implement handling of form submission
        const obj = {
          email: form.email,
          code: form.code,
          customStartDate: formatDateToNLDate(form.startDate),
          customEndDate: formatDateToNLDate(form.endDate),
          startDate: form.startDate,
          endDate: form.endDate,
          amount: form.amount,
        };

        const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(obj),
        };

        const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/discounts/index.php", requestOptions);
        const json = await response.json();
        if (json) {
          if(json.success === true) {
            codes.value.unshift(json.data[0])
            form.code = ''
            form.email = ''
            form.startDate = ''
            form.endDate = ''
            form.amount = ''
          }
        }
      }
    };

    const formatDateToNLDate = (dateString) => {
      // Converteer de string naar een Date-object
      const date = new Date(dateString);

      // Controleer of de conversie succesvol is (om ongeldige datums te vermijden)
      if (isNaN(date)) {
        return "Ongeldige datum";
      }

      // Formatteer de datum naar het Nederlandse formaat
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };

    const deleteCode = async (id) => {
      const obj = {
        cod_id: id,
      };
      const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(obj),
      };
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/discounts/index.php", requestOptions);
      const json = await response.json();
      if (json) {
        // Controleer eerst of this.codes.value bestaat en een array is
        codes.value = codes.value.filter(item => item.cod_id !== id);
      }
    }

    const codeChecker = async () => {
        if (form.code.length >= 9) {
          const obj = {
            selectAll: "true",
            from: "drv_discount_codes",
            testQuery: "false",
            where: {
              st1: "cod_code = '" + form.code + "'",
            }
          };
          const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(obj),
          };
          const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/dynamicGet/index.php", requestOptions);
          const json = await response.json();
          if (json.data.length === 0) {
            return true;
          } else {
            return false;
          }
        }
      }

    // Roep getRequests aan wanneer de component wordt geladen
    return {
      isDarkPage,
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      getRequests, // Toegankelijk in de template of andere methodes
      codes,
      form,
      errors,
      isEmailValid,
      isCodeValid,
      isStartDateValid,
      isEndDateValid,
      isAmountValid,
      codeChecker,
      submitForm,
      validateField,
      deleteCode
    };
  },

  data() {
    return {
      generatedCode:'',
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      finishedDbCall: false
    }
  },

  watch: {
    isLoading: function (value) {
      if (value === false) {
        this.getRequests()
      }
    }
  },


  methods: {
    async generateCode() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let code = '';
      for (let i = 0; i < 9; i++) {
        code += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      this.form.code = code;
      if (!this.codeChecker) {
        await this.generateCode()
      }
    },

    validateAmountInput(event){
      let input = event.target.value;

      // Vervang komma door punt voor consistente decimaalnotatie
      input = input.replace(',', '.');

      // Gebruik een regex om alleen numerieke waarden met maximaal één decimaalpunt toe te staan
      const formattedInput = input.replace(/[^0-9.]/g, '') // Verwijdert alle niet-numerieke tekens behalve "."
          .replace(/(\..*?)\./g, '$1') // Verwijdert extra decimaalpunten
          .replace(/^0+(\d)/, '$1') // Verwijdert leidende nullen
          .replace(/(\.\d{2})\d+/, '$1'); // Beperkt tot twee decimalen

      // Update het inputveld met de geformatteerde waarde
      this.form.amount = formattedInput;
    },
    windowChecker(data) {
      this.windowCheckerData = data;
    },

  },

  created() {
  },

  mounted() {
    this.getRequests()
  }


}
</script>

<style scoped>

.overview-tabs{
  display: flex;
  margin-bottom:  1rem;
  border-bottom: 1px solid #d9d9d9;
  .tab-item{
    background: #d9d9d9;
    padding: 8px 16px;
    border: 1px solid #d9d9d9;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    &:hover{
      background: #dddddd;
      p{
        text-decoration: underline;
        cursor:pointer;
      }
    }
    &.active{
      background: white;
      border-bottom-color:white;
    }
    p{
      border-bottom: 0px;
    }
  }
}
.refresh-button{
  text-align: center;
  background-color: var(--btnBlue);
  border: none;
  font-size: 15px;
  padding: 6px 18px 6px 18px;
  transition: none;
  letter-spacing: 0.6px;
  vertical-align: baseline;
}
.refresh-icon{
  transform: rotate(0deg)
}

.euro-sign{
  font-size: 20px;
  margin-right: 1rem;
}

.du-input-amount{
  width: calc(100% - 1rem - 12px)
}

.du-input-style{
  border: 2px solid #8f8f8f;
  height: 30px;
  text-align: left;
  padding-left: 14px;
  color: var(--darkblue);
  font-size: 15px;
}

@keyframes refresh-animation {
  from {
    transform: rotate(0deg)}
  to {
    transform: rotate(3600deg)}
}
</style>
