<template>
  <title>Welkom | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
          <div class="du-lottie-player">
            <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
          </div>
      </div>

      <div class="col-md-6 col-12 container-content"
           :class="{'cptl cpm': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen, 'font-white': isDarkPage}"
      >
        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeTextXl">Welkom bij Dividenduitkering.nl</h2>
          </div>
          <div class="du-controls-content d-flex">
            <div class="w-100">
              <div class="du-question-control-left d-md-inline-block d-block cmbs" :class="{'w-100': windowCheckerData.isTabletScreen}">

                <div class="cmbm">
                  <!-- <h3>Neem de tijd</h3> -->
                  <p>
                    Bent u bestuurder van een besloten vennootschap en willen de aandeelhouder(s) vermogen
                    uit de vennootschap uitkeren? Met de dividenduitkeringstoets kunt u eenvoudig en goed
                    gestructureerd de besluitvorming over de uitkeringen vastleggen. In een aantal stappen komt
                    u tot een wettelijk verantwoorde onderbouwing van uw besluit over de uitkering.
                  </p>
                </div>
                <div class="cmbm">
                  <p><u>Toetsduur</u>:	20 – 60 minuten	 minuten</p>
                </div>
                <div class="cmbm">
                  <p>
                    Het beoordelen van één uitkering door middel van de toets kost slechts € 150 exclusief btw.
                    U kunt het bedrag na registratie voldoen via iDeal.
                    De uitkeringstoets is niet bedoeld voor uitkeringen vanuit internationale vennootschappen.
                    Uiteraard kunnen wij u hierbij wel ondersteunen. We vragen u daarvoor het <a href="mailto:info@dividenduitkering.nl">contactformulier</a>
                    in te vullen.
                  </p>
                </div>
                <router-link to="/start-aanvraag" class="d-block">
                  <button class="du-button">BEGIN UW AANVRAAG
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 d-md-flex d-none du-lottie-column d-flex align-items-center" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>

import { useAuth0 } from '@auth0/auth0-vue';
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import WindowChecker from "@/components/WindowChecker.vue";
import {useTheme} from "@/components/utils/theme";


export default {
  name: `Welcome`,
  components: {WindowChecker, Navigation, Header, Footer, 'lottie': LottiePlayer},
  setup() {
    const auth0 = useAuth0();
    const { isDarkPage } = useTheme()
    return {
      isDarkPage,
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      login() {
        auth0.loginWithRedirect();
      },
      logout() {
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      }
    }
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },

  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },

      // Initialize a data property to store the received data
      options: {
        minimizable: false,
        playerSize: "standard",
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: "standard",
          active: "light",
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          }
        }
      }
    }
  }
};
</script>

<style scoped>

</style>
