<template>
  <title>Betaling | Dividenduitkering</title>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 du-column-white d-flex flex-column justify-content-center" :class="{
        'min-height cpm': !windowCheckerData.isMobileScreen,
        'cpxm': windowCheckerData.isMobileScreen,
        'min-height-mobile-s': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs': windowCheckerData.isMobileScreenXS
      }">
        <div class="du-title-text du-between-title mt-0 d-flex justify-content-center text-center">
          <h1>Betaling {{orderStatus?.status_name}}</h1>
        </div>
        <div class="du-between-subtext-v2  d-flex justify-content-center">
          <p>{{orderStatus?.paragraph}}</p>

        </div>
        <div class="du-between-button mt-0 d-flex justify-content-center">
          <a v-for="(btn, index) in orderStatus?.btns" :href="btn?.btn_href">
            <div class="du-question-control-right d-inline-block">
              <button :class="btn?.btn_classList + ' du-button'">
                {{btn?.btn_name}}
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
import {useTest} from "@/components/utils/crud-test";
import {useAuth0} from "@auth0/auth0-vue";
import emitter from "@/components/eventBus";
import {onMounted, watchEffect} from "vue";

export default {
  name: "Payment",
  components: {WindowChecker},
  setup(){
    const { createTest } = useTest();
    onMounted(() => {
      createTest(); // Wordt alleen uitgevoerd als paymentStatus 'succes' is en het e-mailadres exclusief is
    });
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
    async orderStatusChecker(status) {
      switch (status) {
        case "succes":
          const storage = localStorage.getItem('discount')
          if(storage){
            const discount = JSON.parse(storage);
            discount.cod_status = 0;
            const requestOptions = {
              method: "PUT",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(discount),
            };
            const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/discounts/index.php", requestOptions);
            const json = await response.json();

            if (json.success === true && json.data !== false) {
              localStorage.removeItem('discount')
            }
          }
          this.orderStatus.status_name = "succes"
          this.orderStatus.paragraph = "Uw betaling was succesvol. U kunt van start gaan met uw aanvraag. Uw aanvraag blijft 24 uur opgeslagen, zodat u het zo goed mogelijk in kan vullen."
          this.orderStatus.btns =
              [
                {
                  btn_classList: "du-button-dark",
                  btn_name: "GA NAAR OVERZICHT",
                  btn_href: `/overzicht`
                }
              ]
          // const storage = localStorage.getItem('drv_tests')

          // const storage = localStorage.getItem('drv_tests')
          // let arr;
          // if (storage) {
          //   arr = JSON.parse(storage);
          //   arr.push(obj)
          // } else {
          //   arr = []
          //   arr.push(obj)
          // }
          // localStorage.setItem('drv_tests', JSON.stringify(arr));
          break;
        case "verlopen":
          this.orderStatus.status_name = "verlopen"
          this.orderStatus.paragraph = "Uw betaling was verlopen. Probeer het opnieuw."
          this.orderStatus.btns =
              [
                {
                  btn_classList: "du-button-dark",
                  btn_name: "OPNIEUW BETALEN",
                  btn_href: "https://drv.imaginecreativeagency.nl/mollie/index.php"
                }
              ]
          break;
        case "annuleert":
          this.orderStatus.status_name = "annuleert"
          this.orderStatus.paragraph = "Uw betaling was annuleert. Probeer het opnieuw of ga terug naar het overzicht."
          this.orderStatus.btns =
              [{
                btn_classList: "du-button-dark",
                btn_name: "OPNIEUW BETALEN",
                btn_href: "https://drv.imaginecreativeagency.nl/mollie/index.php"
              }, {
                btn_classList: "",
                btn_name: "TERUG OVERZICHT",
                btn_href: `/overzicht`
              }]
          break;
        case "mislukt":
          this.orderStatus.status_name = "mislukt"
          this.orderStatus.paragraph = "Uw betaling was verlopen. Probeer het opnieuw."
          this.orderStatus.btns =
              [
                {
                  btn_classList: "du-button-dark",
                  btn_name: "OPNIEUW BETALEN",
                  btn_href: "https://drv.imaginecreativeagency.nl/mollie/index.php"
                }
              ]
          break;
        default:
          this.$router.push("/betaling/error/404");

      }
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Maanden beginnen bij 0
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
  },
  created() {
    this.orderStatus = {
      status_name: "",
      paragraph: "",
      btns:[]
    }
    const payStatus = this.$route.params.paymentStatus
    this.orderStatusChecker(payStatus)
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      mollieLink: "https://drv.imaginecreativeagency.nl/mollie/index.php",
      orderStatus:{
        status_name: "",
        paragraph: "",
        btns:[]
      },
    }
  },
}
</script>

<style scoped>

</style>
