<template>

  <nav class="navbar navbar-expand-md">

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="du-navbar">
        <ul class="nav navbar-nav" :class="isDarkPage ? 'dark-theme' : 'light-theme'">

          <li class="nav-item">
            <router-link class="nav-link" tag="a" to="/">WELKOM</router-link>
            <span v-bind:class = "(activePage === 'Welcome')?'d-block' : ''" class="active-dot"></span>
          </li>

          <li class="nav-item postion-relative">
            <router-link class="nav-link" tag="a" to="/hoe-werkt-het">HOE WERKT HET</router-link>
            <span v-bind:class = "(activePage === 'HowItWorks')?'d-block' : ''" class="active-dot"></span>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" tag="a" to="/start-aanvraag">START UW AANVRAAG</router-link>
            <span v-bind:class = "(activePage === 'StartRequest' || activePage === 'New-Request')?'d-block' : ''" class="active-dot"></span>
          </li>

          <li v-if="isAuthenticated" class="nav-item mb-2 mb-md-0 parent-nav-item">
            <router-link class="nav-link" tag="a" to="/overzicht">OVERZICHT TOETSEN</router-link>
            <span v-bind:class = "(activePage === 'RequestView' || activePage === 'DiscountOverview')?'d-block' : ''" class="active-dot"></span>
            <div v-if="isAdmin" class="nav-item mb-2 mb-md-0 child-nav-item">
              <router-link class="nav-link" tag="a" to="/kortingscodes">KORTINGSCODES</router-link>
            </div>
          </li>

          <li class="nav-item">
            <Auto0></Auto0>
          </li>

<!--          <p v-if="activePage === 'QuestionList'" class="nav-item du-navbar-usertext"><span>|</span> WELKOM 'NAAM' <i class="fa-solid fa-chevron-down du-navbar-usertext-icon"></i></p>-->
<!--          <p v-if="isLoggedIn && user !== null" class="nav-item du-navbar-usertext"><span>|</span> Welkom {{ user.firstName }} <i class="fa-solid fa-chevron-down du-navbar-usertext-icon"></i></p>-->

      </ul>
      </div>
    </div>
  </nav>

</template>


<script>
import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'
import Auto0 from "@/components/auth0.vue";
import { useAuth0 } from '@auth0/auth0-vue';
import { useTheme } from "@/components/utils/theme";
import {checkPermissionByEmail, checkAdminByEmail} from "@/components/utils/check-permission-by-email";

let userStore
let userVar
let loggedIn
export default {
  name: 'Navigation',
  components: {Auto0},

  setup() {
    const { isAuthenticated } = useAuth0();
    const { isDarkPage } = useTheme()
    const { isUserExclusive } = checkPermissionByEmail();
    const { isAdmin } = checkAdminByEmail();

    userStore = useUserStore()
    const {user, isLoggedIn} = storeToRefs(userStore)
    userVar = user.value
    loggedIn = isLoggedIn.value
    return {
      isDarkPage,
      user,
      isLoggedIn,
      isAuthenticated,
      isUserExclusive,
      isAdmin
    }
  },

  data() {
    return {
      fillCount: 0,
    };
  },

  props: {
    activePage: {
      type: String
    }
  },

  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },

  watch: {
    user: {
      // This will let Vue know to look inside the array
      deep: true,
    },
  },

}



</script>
<style>
.navbar-nav{
  align-items: center;
}

.nav-item{
  position: relative;
}
.active-dot{
  background-color:#A1FFE1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 14px;
  right: 8px;
  position: absolute;
  display: none;

  &.d-block{
    display: block!important;
  }
}

.parent-nav-item{
  position: relative;
  &:hover{
    .child-nav-item{
      display: block;
      >*{
        color: #032B28!important;
      }
    }
  }
  .child-nav-item{
    display: none;
    position: absolute;
    top: 60px;
    right: 20px;
    background: white;
    padding: .25rem .5rem;
    border: 1px solid #eee;
    box-shadow: 1px 1px 1px #ddd;
    > *{
      font-size: 13px;
      color: #032B28;
    }
  }
}
</style>
