<template>
  <title>404 | Dividenduitkering</title>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 du-column-white d-flex flex-column justify-content-center" :class="{
        'min-height cpm': !windowCheckerData.isMobileScreen,
        'cpxm': windowCheckerData.isMobileScreen,
        'min-height-mobile-s': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs': windowCheckerData.isMobileScreenXS
      }">
        <div class="du-title-text du-between-title mt-0 d-flex justify-content-center text-center">
          <h1 class="du-error-text">404</h1>
        </div>
        <div class="du-between-subtext-v2  d-flex justify-content-center">
          <p class="du-error-text">De pagina die u probeert te bezoeken is niet beschikbaar. Klik hieronder om terug naar de homepage te gaan.</p>

        </div>
        <div class="du-between-button mt-0 d-flex justify-content-center">
          <router-link to="/">
            <div class="du-question-control-right d-inline-block">
              <button id="verification-button" class="du-button du-button-dark du-button-error">NAAR HOMEPAGE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";

export default {
  name: "PageNotFound",
  components: {WindowChecker} ,
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
}
</script>

<style scoped>

</style>
