<template>
  <div class="wrapper">  <!--class="wrapper"-->
    <div class="topWrapper">
      <Header></Header>
      <navigation :active-page="routeName"></navigation>
    </div>

    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <div :key="route.name">
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view>
  </div>
  <Footer></Footer>
</template>


<script>

import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";


export default {

  name: `HowItWorks`,
  components: {Navigation, Header, Footer},

  data() {
    return {
      routeName: null,
    };
  },

  watch: {
    '$route': 'currentRoute'
  },

  methods: {
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name
        if((this.$route.name) === "VerifyCodeView") {
          document.body.style.backgroundColor = "white";
          const mainLogo = document.getElementById("headerLogo")
          if(mainLogo) {
            mainLogo.src = require("@/assets/Logo_Zwart.png")
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_kleur.png")
          }
        }
        else if (this.$route.name === "QuestionList") {
          document.body.style.backgroundColor = "#FFFFFF";
          const navLinks = document.getElementsByClassName("nav-link")
          navLinks.forEach(el => {
            el.style.color = '#006C75'

          });
          const mainLogo = document.getElementById("headerLogo")
          if(mainLogo) {
            mainLogo.src = require("@/assets/Logo_Zwart.png")
          }
          // const footer = document.querySelector("footer")
          // footer.style.color = 'black'
          const pCopy = document.getElementById("copy")
          pCopy.style.color = "#006C75"
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_kleur.png")
          }
        }
        else if((this.$route.name) === "RegisterView"  ) {
          document.body.style.backgroundColor = "#e1ebf5";
          const navLinks = document.getElementsByClassName("nav-link")
         if(navLinks.length > 0) {
           navLinks.forEach(el => {
             el.style.color = '#006C75'
           });
         }
          const mainLogo = document.getElementById("headerLogo")
          if(mainLogo) {
            mainLogo.src = require("@/assets/Logo_Zwart.png")
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_kleur.png")
          }

          const urls = document.getElementsByClassName("drv-footer-link")
          urls.forEach(el => {
            el.style.color = "#032B28"
          });

          const copyEl = document.getElementById("copy")
          copyEl.style.color = "#032B28"
        }
        // else if((this.$route.name) === "Welcome") {
        //   document.body.style.backgroundColor = "#006C75";
        // }
        else {
          document.body.style.backgroundColor = '#006C75';
          const navLinks = document.getElementsByClassName("nav-link")
          navLinks.forEach(el => {
            el.style.color = 'white'

          });
          const headerLogo = document.getElementById("headerLogo")
          if(headerLogo) {
            headerLogo.src = require("@/assets/Logo_Wit.png")
          }
          const footerLogo = document.getElementById("footerLogo")
          if(footerLogo) {
            footerLogo.src = require("@/assets/Footer_Logo_wit.png")
          }
          const pCopy = document.getElementById("copy")
          if(pCopy) {
            pCopy.style.color = "white"
          }
        }


      });
    }
  },
  async beforeMount() {
    const obj = {
      check_older_tests: true
    }
    const requestOptions = {
      method: "DELETE",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(obj),
    };
    const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/tests/index.php", requestOptions);
    const json = await response.json();
  }

};

</script>


<style>
@import "../src/assets/main.css";
</style>
