<template>
  <title>Hoe werkt het | Dividenduitkering</title>
  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="col-12 d-md-none px-0 cpyl">
        <div class="du-lottie-player">
          <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
        </div>
      </div>
      <div class="col-md-6 col-12 container-content"
           :class="{'cptl cpm': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen, 'font-white': isDarkPage}"
      >
        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeTextXl">Hoe werkt het</h2>
          </div>
          <div class="du-controls-content d-flex">
            <div class="w-100">
              <div class="du-question-control-left d-md-inline-block d-block cmbs" :class="{'w-100': windowCheckerData.isTabletScreen}">

                <div class="cmbm">
                  <p>De uitkeringstoets bestaat uit twee delen: de balanstoets en de betalingscapaciteitstoets. De balanstoets wordt uitgevoerd op basis van de laatst vastgestelde of opgemaakte jaarrekening. Deze balanstoets bevestigt de rechtsgeldigheid van het uitkeringsbesluit van de algemene vergadering.</p>
                  <p>De antwoorden en toelichtingen die u in de betalingscapaciteitstoets geeft, zijn bepalend voor het vervolg van de toets. Naar aanleiding van deze toets kunt u concluderen dat een uitkering mogelijk is of dat nader onderzoek naar de huidige en/of de toekomstige situatie vereist is.</p>
                </div>
                <div class="cmbm">
                  <p><u>Efficiënt invullen</u></p>
                  <p>In de toets worden uiteenlopende feiten en cijfers gevraagd. Het is daarom handig om de volgende gegevens vooraf klaar te leggen:</p>
                </div>
                <div class="cmbm">
                  <ul>
                    <li><p>inschrijfnummer Kamer van Koophandel</p></li>
                    <li><p>namen bestuurders</p></li>
                    <li><p>de laatst vastgestelde of opgemaakte jaarrekening</p></li>
                    <li><p>bedrijfseconomische waarden van fiscaal gewaardeerde posten (o.a. fiscaal (versneld) afgeschreven activa, fiscaal afwijkende voorraadwaardering en pensioenen)</p></li>
                    <li><p>actuele cijfers (b.v. een door het bestuur geautoriseerde saldibalans per heden)</p></li>
                    <li><p>prognoses en begrotingen</p></li>
                  </ul>
                </div>
                <div class="cmbm">
                  <p>
                    De duur van de toets hangt af van het aantal vragen dat in uw specifieke situatie beantwoord moet worden. Het invullen van de toets duurt zo'n 20- 60 minuten. Een en ander zal afhankelijk zijn in hoeverre de toets het nodig acht actuele cijfers en/ of prognoses/ begrotingen te gebruiken
                  </p>
                  <p>Wanneer u tussentijds de toets op reguliere wijze verlaat zullen de ingevoerde data opgeslagen worden: dit wordt dan bevestigd door een (pop-up) melding.</p>
                  <br>
                  <p>LET OP! Gezien de privacy wetgeving worden de gegevens van onderhanden toetsen maximaal 60 dagen bewaard. Heeft u de toets niet afgerond binnen 60 dagen dan zal de data gewist worden en heeft u ook geen toegang meer tot de(ze) toets.</p>
                </div>
                <div class="cmbm">
                  <p><u>Privacy gegarandeerd</u></p>
                  <p>Na afronding van de toets ontvangt u per mail een pdf-bestand. De gegevens worden per direct verwijderd.</p>
                </div>
                <router-link to="/start-aanvraag" class="d-block">
                  <button class="du-button">BEGIN UW AANVRAAG
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 d-md-flex d-none du-lottie-column d-flex align-items-center" :class="{'cptl justify-content-end pe-0': !windowCheckerData.isMobileScreen, 'p-0': windowCheckerData.isMobileScreen}">
        <div class="du-lottie-player h-100">
          <!-- <lottie-player v-pre loop autoplay mode="normal" src="drv.json" style="width: 100%;display: inline-block"></lottie-player> -->
          <img class="isoMetric" src="@/assets/Isometric02.png" alt="IsoMetric">
        </div>
      </div>

    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>

import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import WindowChecker from "@/components/WindowChecker.vue";
import {useTheme} from "@/components/utils/theme";



export default {
  name: `HowItWorks`,
  components: {WindowChecker,Navigation, Header, Footer},
  setup(){
    const { isDarkPage } = useTheme()
    return {
      isDarkPage,
    }
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
    }
  }
};
</script>

<style scoped>

</style>
